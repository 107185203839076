
import { Component, Vue } from "vue-property-decorator";

import { Tag } from "../store/tags";

@Component
export default class UserTagsForm extends Vue {
  tags: Tag[] = [];

  created() {
    this.fetchTags();
  }

  get userTags() {
    return this.$store.getters["tags/data"];
  }

  set userTags(userTags: []) {
    this.$store.commit("tags/setData", userTags);
  }

  fetchTags() {
    this.$store.dispatch("tags/fetch").then(({ data }) => (this.tags = data.data));
  }

  /// TODO:
  /// @deprecated: remove after merging new functionality to production
  ///
  updateUserTagDeprecated() {
    this.$store.dispatch("tags/update", this.userTags).then(() => {
      this.$store.commit("tags/setData", this.userTags);
    });
  }

  updateUserTag(id: number) {
    this.$store.dispatch("tags/updateUserTag", id).then(({ data }) => {
      this.userTags = data;

      this.$store.commit("snack/setSnack", {
        status: "success",
        text: this.$t("SNACKBAR.SUCCESS")
      });
    });
  }
}
