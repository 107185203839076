
import { Component, Vue } from "vue-property-decorator";

import UserTagsForm from "../components/UserTagsForm.vue";
import PersonInterestsForm from "../components/PersonInterestsForm.vue";

@Component({
  components: {
    UserTagsForm,
    PersonInterestsForm
  }
})
export default class PersonalInterestsPage extends Vue {}
