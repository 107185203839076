
import { Component, Vue } from "vue-property-decorator";

import { Interest } from "../store/tags";

@Component
export default class PersonInterestsForm extends Vue {
  additionalInterest = "";

  get personInterests() {
    return this.$store.getters["tags/personInterestsData"];
  }

  set personInterests(personInterests: Interest[]) {
    this.$store.commit("tags/setPersonInterestsData", personInterests);
  }

  storePersonInterest() {
    const formData = {
      interest: this.additionalInterest
    };

    this.$store.dispatch("tags/storePersonInterest", formData).then(({ data }) => {
      this.personInterests = data;
      this.additionalInterest = "";

      this.$store.commit("snack/setSnack", {
        status: "success",
        text: this.$t("SNACKBAR.SUCCESS")
      });
    });
  }

  removePersonInterest(id: number) {
    this.$store
      .dispatch("tags/removePersonInterest", id)
      .then(({ data }) => (this.personInterests = data));
  }
}
